const ActionType = {
  SET_SOCKET_TOKEN: `SET_SOCKET_TOKEN`,
  REMOVE_AUTHORIZATION: `REMOVE_AUTHORIZATION`,
  SET_CURRENT_PROFILE: `SET_CURRENT_PROFILE`,
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_AUTH: 'SET_AUTH',
  SET_AUTH_ONETIME: 'SET_AUTH_ONETIME',
}

export default ActionType
