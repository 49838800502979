import ActionType from './action-type'
import { INotification, IReducer } from '../data-types'
import { NavLineStatus } from 'constants/types/navbar.types'

const initialState = {
  notificationMessages: [] as INotification[],
  lineStatus: {
    [NavLineStatus.ACTIVE_LINE]: '',
    [NavLineStatus.DOZATOR_LINE_DISABLED]: false,
    [NavLineStatus.REC_LINE_CLOSED]: false,
    [NavLineStatus.PROFILE_LINE_CLOSED]: false,
  },
  navbarHeight: 68,
  activeWebinars: {
    isLoaded: false,
    data: {},
  },
  searchText: '',
}

const reducer = (state = initialState, { type, payload }: IReducer): any => {
  switch (type) {
    case ActionType.SET_NOTIFICATION_MESSAGES:
      return {
        ...state,
        notificationMessages: payload.notificationMessages,
      }
    case ActionType.SET_SINGLE_NOTIFICATION:
      return {
        ...state,
        notificationMessages: state.notificationMessages.reduce((acc, item) => {
          if (item._id === payload.message._id) {
            return [...acc, payload.message]
          }
          return [...acc, item]
        }, [] as INotification[]),
      }
    case ActionType.SET_LINE_STATUS:
      return {
        ...state,
        lineStatus: { ...state.lineStatus, ...payload.lineStatus },
      }
    case ActionType.SET_NAVBAR_HEIGHT:
      return {
        ...state,
        navbarHeight: payload.navbarHeight,
      }
    case ActionType.SET_ACTIVE_WEBINARS:
      return {
        ...state,
        activeWebinars: {
          isLoaded: true,
          data: payload.activeWebinars,
        },
      }
    case ActionType.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload.text,
      }
    case ActionType.SET_DOZATOR_LINE:
      return {
        ...state,
        lineStatus: { ...state.lineStatus, [NavLineStatus.DOZATOR_LINE_DISABLED]: payload.value },
      }
    default:
      return state
  }
}

export { reducer }
