import { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'

import Auth from 'store/auth'
import useUpdateEffect from 'hooks/useUpdateEffect'

import { getAxiosSingle } from 'pages/api/axios'
import { cutHashFromURL } from 'utils/getURLData'
import { nestApiPath } from 'constants/url'

const isValidPath = (route: string) => {
  const validPaths = ['/webinar/[id]', '/article/[id]', '/podcast/[id]']

  return validPaths.includes(route) ? true : false
}

const useOpenerMaterial = () => {
  const router = useRouter()
  const isFakeAuth = useRef(false)
  const historyRoute = useRef({
    prevPath: '',
    currentPath: cutHashFromURL(router.asPath),
  })

  const { isAuthorized } = Auth.useAuth()
  const { removeAuthorization, setAuthOneTime } = Auth.useHandleAuth()

  useUpdateEffect(() => {
    const tempPath = historyRoute.current.currentPath

    historyRoute.current.currentPath = cutHashFromURL(router.asPath)
    historyRoute.current.prevPath = tempPath
  }, [router.asPath])

  useEffect(() => {
    if (historyRoute.current.prevPath === historyRoute.current.currentPath) return

    if (isFakeAuth.current) {
      removeAuthorization()
      isFakeAuth.current = false
    }

    if (isAuthorized) return

    if (isValidPath(router.route) && router.asPath.toLowerCase().includes('maid=')) {
      isFakeAuth.current = true
      const findUserMA = async () => {
        setTimeout(async () => {
          const findUserData = await getAxiosSingle(
            `${nestApiPath}/unauth-user/bymaid?maId=${router.query.maId || router.query.maid}`
          )
          findUserData.userId && setAuthOneTime(findUserData.userId)
          // Timeout нужен, чтобы первпя сессия отправлялась uid бразуреа, другие решения, как будто усложняют код
        }, 200)
      }
      findUserMA()
    }

    if (isValidPath(router.route) && router.asPath.includes('spec=')) {
      isFakeAuth.current = true
      setAuthOneTime(1)
    }
  }, [router.asPath])
}

export default useOpenerMaterial
