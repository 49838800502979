import { useEffect, useRef } from 'react'
// Хук для скипа 1 рендера . Например на лид магните, когда ты входишь в аккаунт га странице лид магнита, тебя подписывают на лид магнит. И если ты уже авторизован и переходишь на лид магнит, то без этого хука не обойтись
const useUpdateEffect = (cb: () => void, deps: any[]): void => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      return cb()
    }
  }, deps)
}
export default useUpdateEffect
